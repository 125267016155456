<template>
    <div class="ruleView">
        <div class="rule_title">活动规则</div>
        <ul class="rule_text">
            <li>
                1.分享推荐二维码，新用户扫描登录小程序，即
                自动绑定，双方各得一张20元优惠券，推荐得新
                用户完成首单，推荐人得到10元现金收益
            </li>
            <li>
                2.邀请有效用户(完成登录和首单完成)达到指定人
                数可拆红包(每个任务的完成数独立)
                <p>第一关：完成5各有效用户推广得20元红包</p>
                <p>第二关：完成10各有效用户推广得50元红包</p>
                <p>第三关：完成50各有效用户推广得300元红包</p>
                <p>第四关：完成100各有效用户推广得600元红包</p>
            </li>
            <li>
                3.收益提现：每日最多可发起一次提现，金额
                不能低于100元，最高不能超过2000元
            </li>
            <li>
                4.本活动遵循救援云法律声明，规则解释权归救
                援云所有，凡是在推广期间以任何形式作弊的救
                援云用户，一经查出，直接取消参与资格，情节
                严重者做封号处理。
            </li>
        </ul>
    </div>
</template>

<script>
export default {

}
</script>

<style lang='scss' scoped>
    .ruleView{
        width: 335px;
        height: 512px;
        background: #fff;
        border-radius: 8px;
        padding: 15px 15px 30px;
        box-sizing: border-box;
        text-align: left;
        .rule_title{
            width: 133px;
            height: 38px;
            background: #E1DEFF;
            box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08);
            border-radius: 26px;
            text-align: center;
            line-height: 38px;
            margin: 0 auto;
        }
        .rule_text{
            li{
                margin-top: 10px;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                line-height: 25px;
                color: #141414;
                letter-spacing: 0.5px;
                p{
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    line-height: 25px;
                    color: #FF4B13;
                }
            }
        }
    }
</style>