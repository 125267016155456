<template>
    <div class='active'>
        <div class="hearde_bg">
            <div class="ruleDialog" @click="ruleView">
                <span>活动规则</span>
                <img src="@/assets/activeImgs/rule_icon.png" alt="" class="rule_icon"/>
            </div>
            <div class="activeInfo">
                <div class="info_title">邀请一名用户得</div>
                <div class="info_hearder">
                    <div class="moneyBg moneyBg1">
                        <span>优惠券</span>
                        <span>20元</span>
                    </div>
                    <img src="@/assets/activeImgs/active_jia.png" alt="" class="active_jia">
                    <div class="moneyBg moneyBg2">
                        <span>现金</span>
                        <span>10元</span>
                    </div>
                </div>
                <div class="userNum">
                    <div class="user_title">邀请指定人数再得奖励</div>
                    <div class="user_money">
                        <div class="numList">
                            <span v-for="(item,index) in moneyList" :key="index">{{ item.title }}</span>
                        </div>
                        <div class="moneyList">
                            <div v-for="(item,index) in moneyList" :key="index" class="active_hb_box">
                                <img v-if="index == 0" src="@/assets/activeImgs/active_hb1.png" alt="" class="active_hb" />
                                <img v-if="index == 1" src="@/assets/activeImgs/active_hb2.png" alt="" class="active_hb" />
                                <img v-if="index == 2 || index == 3" src="@/assets/activeImgs/active_hb3.png" alt="" class="active_hb" />
                                <span class="hb_money">{{ item.money }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <img src="@/assets/activeImgs/active_btn.png" alt="" class="active_btn" @click="toShare">
            </div>
        </div>
        <div class="active_data">
            <div class="data_content">
                <div class="data_userInfo">
                    <img src="@/assets/activeImgs/tx.jpeg" alt="" class="user_tx" />
                    <span>小猫咪</span>
                </div>
                <div class="dataList dataInfo">
                    <div class="dataInfo_hearder">
                        <div class="dataInfo_li">
                            <div class="shareInfo">
                                <img src="@/assets/activeImgs/sahreIcon1.png" alt="" />
                                <div class="shareUser">
                                    3<span>人</span>
                                </div>
                            </div>
                            <div class="shareDesc">成功邀请</div>
                        </div>
                        <div class="dataInfo_li">
                            <div class="shareInfo">
                                <img src="@/assets/activeImgs/sahreIcon2.png" alt="" />
                                <div class="shareUser">
                                    15<span>元</span>
                                </div>
                            </div>
                            <div class="shareDesc">累计收益</div>
                        </div>
                        <div class="dataInfo_li">
                            <div class="shareInfo">
                                <img src="@/assets/activeImgs/sahreIcon3.png" alt="" />
                                <div class="shareUser">
                                    50<span>元</span>
                                </div>
                            </div>
                            <div class="shareDesc">成功邀请</div>
                        </div>
                    </div>
                    <div class="dataInfo_btn dataInfo_btn1" @click="toWithdrawal">去提现</div>
                    <div class="dataInfo_desc">每日最多可提现2次，最少不低于100元</div>
                </div>
                <div class="dataList dataInfo">
                    <div class="dataInfo_title">优惠券</div>
                    <div class="dataInfo_hearder">
                        <div class="dataInfo_li">
                            <div class="shareInfo">
                                <div class="shareUser">
                                    1<span>张</span>
                                </div>
                            </div>
                            <div class="shareDesc">未使用</div>
                        </div>
                        <div class="dataInfo_li">
                            <div class="shareInfo">
                                <div class="shareUser">
                                    15<span>张</span>
                                </div>
                            </div>
                            <div class="shareDesc">累计获得</div>
                        </div>
                        <div class="dataInfo_li">
                            <div class="shareInfo">
                                <div class="shareUser">
                                    50<span>张</span>
                                </div>
                            </div>
                            <div class="shareDesc">已过期</div>
                        </div>
                    </div>
                    <div class="dataInfo_btn dataInfo_btn2">去使用</div>
                    <!--  @click="toMiniWeapp" -->
                    <!-- <wx-open-launch-weapp          
                        username="gh_xxxxxxxx"          
                            path="pages/home/index.html"
                        >       
                        <script type="text/wxtag-template">         
                            <style>.btn { padding: 12px}</style>
                            <button class="btn">去使用</button>      
                            </script>     
                    </wx-open-launch-weapp> -->
                    <div class="dataInfo_desc">每个订单最多可用1张，每张面值20元</div>
                </div>
                <div class="dataList userList">
                    <div class="dataInfo_title">邀请记录</div>
                    <div class="table">
                        <div class="table_title">
                            <div class='title_li'>姓名</div>
                            <div class='title_li_info'>注册</div>
                            <div class='title_li_info'>首次下单</div>
                        </div>
                        <ul class="table_list">
                            <li v-for="(item,index) in userList" :key="index" class="table_list_li">
                                <div class="table_list_user">
                                    <img :src="item.url" alt="">
                                    <span>{{ item.name }}</span>
                                </div>
                                <div class="table_list_info">
                                    <span :style=" item.status1 ? '' : 'color: #FF4B13;' ">{{ item.status1 ? '通过' : '未通过' }}</span>
                                    <span class="table_list_money" :style=" item.status1 ? 'color: #FF4B13;' : 'color: #8A8A8A;' ">￥{{ item.money1 }}</span>
                                    <span v-if="!item.status1" class="table_list_btn">提醒</span>
                                </div>
                                <div class="table_list_info">
                                    <span :style=" item.status2 ? '' : 'color: #FF4B13;' ">{{ item.status2 ? '通过' : '未通过' }}</span>
                                    <span class="table_list_money" :style=" item.status2 ? 'color: #FF4B13;' : 'color: #8A8A8A;' ">￥{{ item.money2}}</span>
                                    <span v-if="!item.status2" class="table_list_btn">提醒</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="ruleMask" v-if="ruleDialog">
            <div :class="[ 'ruleViewDialog', ruleDialog ? 'ruleDialogShow2' : '' ]">
                <ruleDialog />
                <img src="@/assets/activeImgs/close_dialog.png" alt="" @click="closeDialog"/>
            </div>
        </div>
    </div>
</template>

<script>
import ruleDialog from '@/components/activity/rule';
import { getWXConfig } from '@/apis/wxapi';
// import wx from 'weixin-js-sdk'

export default {
    data(){
        return {
            wxConfig: '',
            ruleDialog: false,
            moneyList: [
                {
                    money: 20,
                    title: '已达成'
                },
                {
                    money: 50,
                    title: '已达成'
                },
                {
                    money: 300,
                    title: '还差50'
                },
                {
                    money: 600,
                    title: '还差100'
                },
            ],
            userList: [
                {
                    name: '小猫咪',
                    url: 'https://img0.baidu.com/it/u=251289958,1860898046&fm=26&fmt=auto&gp=0.jpg',
                    status1: false,
                    money1: 10,
                    status2: false,
                    money2: 20,
                },
                {
                    name: '小猫咪',
                    url: 'https://img0.baidu.com/it/u=251289958,1860898046&fm=26&fmt=auto&gp=0.jpg',
                    status1: true,
                    money1: 10,
                    status2: false,
                    money2: 20,
                },
                {
                    name: '小猫咪',
                    url: 'https://img0.baidu.com/it/u=251289958,1860898046&fm=26&fmt=auto&gp=0.jpg',
                    status1: true,
                    money1: 10,
                    status2: true,
                    money2: 20,
                },
                {
                    name: '小猫咪',
                    url: 'https://img0.baidu.com/it/u=251289958,1860898046&fm=26&fmt=auto&gp=0.jpg',
                    status1: false,
                    money1: 10,
                    status2: false,
                    money2: 20,
                },
            ]
        }
    },
    components: {
        ruleDialog
    },
    mounted(){
        // let params = {
        //     url: document.location.href
        // }
        // getWXConfig(params).then((res)=>{
        //     console.log(res,'wx')
        //     this.wxConfig = res.data.data;
        //     wx.config({ //  通过后端
        //         debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
        //         appId: this.wxConfig.appId, // 必填，公众号的唯一标识
        //         timestamp: this.wxConfig.timestamp, // 必填，生成签名的时间戳
        //         nonceStr: this.wxConfig.nonceStr, // 必填，生成签名的随机串
        //         signature: this.wxConfig.signature, // 必填，签名，见附录1
        //         jsApiList: this.wxConfig.jsApiList, // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        //         openTagList: [
        //             'wx-open-launch-weapp'
        //         ]
        //     })
        //     wx.ready(function (r) {
        //         // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中
                
        //     })
        //     wx.error(function (e) {
        //         console.log(e,'111')
        //     // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
        //     })
        // })
    },
    methods: {
        ruleView(){
            this.ruleDialog = true;
        },
        closeDialog(){
            this.ruleDialog = false;
        },
        // 推广海报
        toShare(){
            this.$router.push({
                path: '/poster'
            })
        },
        // 提现
        toWithdrawal(){
            this.$router.push({
                path: '/withdrawal'
            })
        },
        toMiniWeapp(){
            // console.log(wx.miniProgram,'wx.miniProgram')
            // wx.miniProgram.getEnv(function(res) {
            //     console.log(res) // true
            //     if(res.miniprogram){
            //         wx.miniProgram.switchTab({
            //             url: '/pages/example/index/index'
            //         })
            //     }
            // })
        }
    }
}
</script>

<style lang='scss' scoped>
    .active{
        .hearde_bg{
            width: 100%;
            height: 667px;
            background: url('../../assets/activeImgs/active_bg.png');
            background-size: 100% 100%;
            padding-top: 185px;
            box-sizing: border-box;
            position: relative;
            .ruleDialog{
                width: 80px;
                height: 30px;
                background: #250FB2;
                opacity: 1;
                border-radius: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                font-family: PingFang SC;
                font-weight: 400;
                line-height: 17px;
                color: #FFFFFF;
                position: absolute;
                top: 15px;
                right: 15px;
                .rule_icon{
                    width: 11px;
                    height: 7px;
                    margin-left: 4px;
                }
            }
            .activeInfo{
                width: 100%;
                height: 382px;
                background: url('../../assets/activeImgs/active_user.png');
                background-size: 100% 100%;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                .info_title{
                    font-size: 15px;
                    font-family: FZZYJW--GB1-0;
                    line-height: 17px;
                    color: #fff;
                    letter-spacing: 3px;
                    margin-top: 12px;
                }
                .info_hearder{
                    margin-top: 26px;
                    margin-bottom: 18px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .moneyBg{
                        width: 117px;
                        height: 65px;
                        padding: 9px 0 16px 18px;
                        box-sizing: border-box;
                        color: #fff;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        color: #FF3200;
                        span:first-child{
                            font-family: AdobeHeitiStd-Regular;
                            line-height: 14px;
                            font-size: 12px;
                        }
                        span:last-child{
                            font-size: 21px;
                            font-family: SourceHanSansCN-Medium;
                            line-height: 21px;
                        }
                    }
                    .moneyBg1{
                        background: url('../../assets/activeImgs/active_money1.png');
                        background-size: 100% 100%;
                    }
                    .moneyBg2{
                        background: url('../../assets/activeImgs/active_money2.png');
                        background-size: 100% 100%;
                    }
                    .active_jia{
                        width: 23px;
                        height: 23px;
                        margin: 0 16px;
                    }
                }
                .userNum{
                    width: 293px;
                    height: 191px;
                    background: url('../../assets/activeImgs/active_bbj.png');
                    background-size: 100% 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .user_title{
                        font-size: 15px;
                        font-family: PingFang SC;
                        font-weight: bold;
                        line-height: 21px;
                        color: #C00E30;
                        letter-spacing: 3px;
                        margin-top: 26px;
                    }
                    .user_money{
                        width: 242px;
                        .numList{
                            display: flex;
                            justify-content: space-between;
                            font-size: 12px;
                            font-family: PingFang SC;
                            font-weight: 400;
                            color: #4C4C4C;
                            margin: 17px 0 6px 0;
                            span{
                                width: 54px;
                                text-align: center;
                            }
                        }
                        .moneyList{
                            height: 7px;
                            background: #FFD9D2;
                            border-radius: 4px;
                            display: flex;
                            justify-content: space-between;
                            .active_hb_box{
                                width: 54px;
                                height: 71px;
                                position: relative;
                                display: flex;
                                justify-content: center;
                                .active_hb{
                                    width: 100%;
                                    height: 100%;
                                }
                                .hb_money{
                                    position: absolute;
                                    bottom: 0;
                                    font-size: 12px;
                                    font-family: PingFang SC;
                                    font-weight: 800;
                                    line-height: 17px;
                                    color: #FEFEFE;
                                }
                            }
                        }
                    }
                }
                .active_btn{
                    width: 291px;
                    height: 59px;
                    position: absolute;
                    bottom: -18px;
                }
            }
        }
        .active_data{
            display: flex;
            justify-content: center;
            background: #4427FC;    
            position: absolute;
            width: 100%;
            top: 598px;
            padding-bottom: 37px;
            .data_content{
                width: 339px;
                min-height: 766px;
                background: #250FB2;
                opacity: 1;
                border-radius: 8px;
                padding: 15px;
                box-sizing: border-box;
                .data_userInfo{
                    display: flex;
                    align-items: center;
                    .user_tx{
                        width: 34px;
                        height: 34px;
                        border-radius: 50%;
                        margin-right: 5px;
                    }
                    span{
                        font-size: 16px;
                        font-family: PingFang SC;
                        font-weight: bold;
                        line-height: 23px;
                        color: #FFFFFF;
                    }
                }
                .dataList{
                    width: 309px;
                    // height: 225px;
                    background: #FFFFFF;
                    opacity: 1;
                    border-radius: 8px;
                    margin-top: 15px;
                    .dataInfo_title{
                        font-size: 16px;
                        font-family: PingFang SC;
                        font-weight: bold;
                        line-height: 23px;
                        color: #FF4B13;
                        letter-spacing: 1px;
                        margin-bottom: 15px;
                    }
                }
                .dataInfo{
                    padding: 15px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .dataInfo_hearder{
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        .dataInfo_li{
                            width: 83px;
                            height: 67px;
                            background: #FFF6E4;
                            box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.09);
                            opacity: 1;
                            border-radius: 4px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: center;
                            padding: 8px 0 10px 0;
                            box-sizing: border-box;
                            .shareInfo{
                                display: flex;
                                align-items: center;
                                .shareUser{
                                    font-size: 20px;
                                    font-family: PingFang SC;
                                    font-weight: bold;
                                    line-height: 28px;
                                    color: #FF4B13;
                                    span{
                                        font-size: 12px;
                                        font-family: PingFang SC;
                                        font-weight: 400;
                                        line-height: 17px;
                                        color: #FF4B13;
                                        margin-left: 4px;
                                    }
                                }
                                img{
                                    width: 16px;
                                    height: 16px;
                                    margin-right: 7px;
                                }
                            }
                            .shareDesc{
                                font-size: 12px;
                                font-family: PingFang SC;
                                font-weight: 400;
                                line-height: 17px;
                                color: #4E3518;
                            }
                        }
                    }
                    .dataInfo_btn{
                        width: 250px;
                        height: 40px;
                        border-radius: 11px;
                        font-size: 16px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        letter-spacing: 2.5px;
                        text-align: center;
                        line-height: 40px;
                        margin: 20px 0 13px 0;
                    }
                    .dataInfo_btn1{
                        background: linear-gradient(180deg, #7C67FF 0%, #4427FC 100%);
                        box-shadow: 0px 2px 6px rgba(37, 15, 178, 0.39);
                    }
                    .dataInfo_btn2{
                        background: #FF4B13;
                        box-shadow: 0px 2px 6px rgba(168, 69, 34, 0.65);
                    }
                    .dataInfo_desc{
                        font-size: 12px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        line-height: 17px;
                        color: #8A8A8A;
                        letter-spacing: 1px;
                    }
                }
                .userList{
                    padding: 15px 10px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .table{
                        width: 100%;
                        .table_title{
                            width: 100%;
                            display: flex;
                            margin-bottom: 15px;
                            font-size: 14px;
                            font-family: PingFang SC;
                            font-weight: bold;
                            line-height: 20px;
                            color: #4E3518;
                            .title_li{
                                width: 30%;
                            }
                            .title_li_info{
                                width: 35%;
                            }
                        }
                        .table_list{
                            .table_list_li{
                                display: flex;
                                margin-bottom: 15px;
                                font-size: 12px;
                                color: #141414;
                                .table_list_user{
                                    width: 30%;
                                    height: 29px;
                                    display: flex;
                                    align-items: center;
                                    img{
                                        width: 29px;
                                        height: 100%;
                                        border-radius: 50%;
                                        margin-right: 5px;
                                    }
                                }
                                .table_list_info{
                                    width: 35%;
                                    height: 29px;
                                    line-height: 29px;
                                    display: flex;
                                    align-items: center;
                                    .table_list_money{
                                        margin: 0 3px;
                                    }
                                    .table_list_btn{
                                        display: inline-block;
                                        width: 30px;
                                        height: 18px;
                                        background: #FF4B13;
                                        border-radius: 9px;
                                        font-size: 10px;
                                        font-family: PingFang SC;
                                        font-weight: 400;
                                        color: #FFFFFF;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .ruleMask{
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(20, 20, 20, 0.51);
            display: flex;
            flex-direction: column;
            align-items: center;
            .ruleViewDialog{
                // margin-top: 62px;
                // z-index: 99;
            }
            .ruleViewDialog {
                z-index: 99;
                position: absolute;
                top: 0;
                left: 0;
                text-align: center;
                transform: translate(-50%,0);
                -webkit-transform: translate(-50%,0);
            }
            .ruleViewDialog.ruleDialogShow2 {
                top: 62px;
                left: 50%;
                animation: show 1s;
                -webkit-animation: show 1s;
            }
            @keyframes show {
                0% {
                    top: -200%;
                    transform: -webkit-translate(-50%, 62px);
                }
                80% {
                    transform: -webkit-translate(-50%, 62px);
                }
                90% {
                    transform: -webkit-translate(-50%, 62px);
                }
                100% {
                    top: 62px;
                    transform: -webkit-translate(-50%, 62px);
                }
            }
            img{
                width: 48px;
                height: 48px;
                margin-top: 15px;
                z-index: 99;
            }
        }
    }
</style>
